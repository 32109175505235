@use 'sass:map';
@use 'sass:color';

@import '../scss/utils/variables';
@import '../scss/utils/mixins';

.content {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;

  > div {
    font-weight: 400;
  }
}

.confirmation {

  &Modal {

    &Body {
      @include flexbox($fd: column, $jc: center);
      background-color: rgba(255,255,255,0.9)!important;
      border-top: {
        left-radius: 8px;
        right-radius: 8px;
      }
      height: 100%;
      max-height: none!important;
    }
  }
}

.confirmation-modal {
    z-index: 1002;
 
  .modal-dialog {
    transform: none !important;
    transition: opacity 0.3s linear;
  }

}

/* .confirmation-modal-header {
      styles for the modal header 
  } */

// .confirmation-modal-body {
//   /*height:100vh;*/
//   /* opacity: 0.9; */
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: left;
//   height: 100%;
//   /* or set a specific height */
//   background-color: #ffffffd9 !important;
//   max-width: 100%;
// }
.confirmation-modal-body .remove {
  /*height:100vh;*/
  /* opacity: 0.9; */
  color: #ffffff;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  height: 120px;
  /* or set a specific height */
  background-color: #151e5b !important;
  max-width: 100%;
}

/* .confirmation-modal-footer {
    styles for the modal footer 
} */
.modal-exit {
  transition:
    opacity 0.3s ease,
    transform 3s ease;
  opacity: 1;
  transform: translateY(0);
}

.modal-exit-active {
  opacity: 0;
  transform: translateY(-50px);
}
