@use 'sass:map';
@use 'sass:color';

@import 'scss/utils/variables';
@import 'scss/utils/mixins';


// Custom mixins for buttons
@mixin getButtonStyles($color, $labelColor: map.get($colors,'white'), $type) {
  background-color: map.get($colors, $color);

  @if $type == 'outlined' {
    border-color: map.get($colors, $labelColor);

    &:hover {
      background-color: map.get($colors, $labelColor);

      @if $labelColor == 'white' or $labelColor == '#ffffff' {
        color: map.get($colors, 'primaryBlue');
      }
      @else {
        color: map.get($colors, $color);
      }
    }
  }
  @if $type == 'contained' {
    border-color: map.get($colors, $color);
    
    &:hover {
      background-color: darken(map.get($colors, $color), 10%);
      border-color: darken(map.get($colors, $color), 10%);
    }
  }

  color: map.get($colors, $labelColor);
}

@mixin getLinkStyles($color, $type) {
  background-color: map.get($colors, 'transparent');
  border: unset;
  color: map.get($colors, $color);
  text-decoration: underline;

  &:hover {
    color: darken(map.get($colors, $color), 10%);
  }
}


// Global styles used by all buttons
.button {
  @include border-rad(4px);
  @include padx(20px);
  @include pady(9px);
  border: {
    style: solid;
    width: 1px;
  };
  font: {
    family: var(--font-inter);
    size: 12px;
    weight: 700;
  };
  gap: 5px;
  justify-content: center;
  line-height: 100%;

  &.inTable {
    @include padx(14px);
    @include pady(6px);
    height: 22px;
  }
}


.primary {
  @include getButtonStyles('primaryBlue', 'white', 'contained');

  &Outlined {
    @include getButtonStyles('white', 'primaryBlue', 'outlined');
  }

  &Link {
    @include getLinkStyles('primaryBlue', 'link');
  }
}

.secondary {
  @include getButtonStyles('blue', 'white', 'contained');

  &Outlined {
    @include getButtonStyles('white', 'blue', 'outlined');
  }

  &Link {
    @include getLinkStyles('blue', 'link');
  }
}

.tertiary {
  @include getButtonStyles('tertiary', 'white', 'contained');

  &Outlined {
    @include getButtonStyles('white', 'tertiary', 'outlined');
  }

  &Link {
    @include getLinkStyles('tertiary', 'link');
  }
}

.quarternary {
  @include getButtonStyles('quarternary', 'white', 'contained');

  &Outlined {
    @include getButtonStyles('white', 'quarternary', 'outlined');
  }

  &Link {
    @include getLinkStyles('quarternary', 'link');
  }
}

.white {
  @include getButtonStyles('white', 'primaryBlue', 'contained');

  &Outlined {
    @include getButtonStyles('transparent', 'white', 'outlined');
  }

  &Link {
    @include getLinkStyles('white', 'link');
  }
}