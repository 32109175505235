@use 'sass:map';

@import 'scss/utils/variables';
@import 'scss/utils/mixins';

.formItemGroup {
  @include flexbox($fd: row, $jc: space-between, $gap: 20px);
  background-color: map.get($colors, 'smoke');

  > * { flex: 1; }

  .title {
    color: map.get($colors, 'black');
    font: {
      family: var(--font-inter);
      size: 12px;
      weight: 700;
    }
    // width: 100%;
  }

  button {
    @include pady(6px);
  }

  &.stacked {
    @include flexbox($ai: flex-start, $fd: column, $gap: 10px);

    > div {
      max-width: unset;
      width: 100%;

      &:not(.formItemGroup) {
        @include flexbox($ai: flex-start, $gap: 8px);
        @include padxy(0);

        > div {
          max-width: unset;
          width: 100%;

          &:first-of-type { // label
            max-width: unset;

            label {
              color: map.get($colors, 'black');
              font: {
                size: 12px;
                weight: 700;
              }
              line-height: 12px;
            }
          }
        }
      }
    }
  }

  &.notStacked {
    // @include flexbox($fd: row, $jc: space-between, $gap: 20px);
    flex-direction: row!important;
  }

  &.typeCustodian {
    background-color: map.get($colors, 'white');
  }
}