.card {
  background-color: var(--gray-0);
  border-radius: 8px;
  box-shadow: var(--shadow-secondary);

  cursor: pointer;

  padding: 16px;
  width: 100%;

  > div > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }
}
