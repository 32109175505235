.container {
  background-color: var(--gray-0);
  min-height: 100vh;
  padding-top: 32px;

  @media screen and (min-width: 576px) {
    padding-top: 64px;
  }

  .block {
    margin: auto;
    max-width: 360px;
    width: 80%;

    form {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      gap: 32px;

      position: relative;

      > * {
        width: 100%;
      }

      > div > div {
        justify-content: center;
      }
    }
  }
}

.formBlock {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 24px;

  > * {
    width: 100%;
  }

  .intro {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 12px;

    h1,
    p {
      text-align: center;
    }

    h1 {
      color: var(--text-tertiary-900);
      font:
        600 20px/28px "Inter",
        sans-serif;

      @media screen and (min-width: 576px) {
        font-size: 30px;
        line-height: 38px;
      }
    }

    p {
      color: var(--text-tertiary-600);
      font:
        400 12px/20px "Inter",
        sans-serif;

      @media screen and (min-width: 576px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .elements {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    > * {
      width: 100%;
    }
  }
}

.errorBox {
  background-color: var(--gray-0);
  border: 2px solid var(--red-700);
  border-radius: 12px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.25);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 55px;

  min-height: 300px;
  width: calc(100% + 20px) !important;

  padding: 36px 24px;

  position: absolute;
  inset: 48px auto -12px auto;
  z-index: 1;

  > div:first-of-type > svg circle {
    fill: var(--red-700);
  }

  .msg {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    h2 {
      color: var(--red-700);
      font:
        600 30px/38px "Inter",
        sans-serif;
    }
  }

  button {
    width: 100%;
  }
}

.okBox {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;

  position: relative;

  .box {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    > input {
      display: none;
    }

    > label {
      color: var(--text-tertiary-900);
      font:
        400 12px/20px "Inter",
        sans-serif;
    }
  }
}
