.titleBlock {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  > button {
    margin-left: auto;
  }
}
