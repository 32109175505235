.site {
  position: relative;

  height: 100%;

  @media only screen and (min-width: 576px) {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  .main {
    flex: 1;
    padding: 32px;

    @media only screen and (min-width: 576px) {
      padding-left: 344px;
    }
  }
}
