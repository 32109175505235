.container {
  border-radius: 8px;
  border: 1px solid var(--bg-disabled);
  background-color: var(--brand-secondary);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  img {
    object-fit: contain;
  }
}
