@use 'sass:map';

@import 'scss/utils/variables';
@import 'scss/utils/mixins';

.formGroupItem {
  @include flexbox($jc: space-between);
  background-color: map.get($colors, 'smoke');

  &.stacked {
    @include flexbox($ai: flex-start, $fd: column);

    > div {
      @include flexbox($ai: flex-start, $fd: column, $gap: 8px);
      @include padxy(0);
      max-width: unset;
      width: 100%;

      > div {
        max-width: unset;

        &:first-of-type { // label
          max-width: unset;

          label {
            color: map.get($colors, 'black');
            font: {
              size: 12px;
              weight: 700;
            }
            line-height: 12px;
          }
        }
      }
    }
  }

  &.typeCustodian {
    background-color: map.get($colors, 'white');
  }
}