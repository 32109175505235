.formEl {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3px;

  width: 100%;

  @media screen and (min-width: 576px) {
    gap: 6px;
  }

  > * {
    width: 100%;
  }

  label {
    color: var(--text-secondary-700);
    font:
      500 12px/18px "Inter",
      sans-serif;

    @media screen and (min-width: 576px) {
      font-size: 14px;
      line-height: 20px;
    }

    > span {
      color: var(--red-base);
    }
  }

  input,
  textarea {
    background-color: var(--gray-0);
    border: 1px solid var(--border-primary);
    border-radius: 8px;
    box-shadow: var(--shadow-primary);

    color: var(--text-placeholder);
    font:
      400 14px/22px "Inter",
      sans-serif;

    padding: 10px 14px;
    width: 100%;

    /* @media screen and (min-width: 576px) {
      font-size: 16px;
      line-height: 24px;
    } */

    &:focus {
      outline: none;
    }
  }

  textarea {
    min-height: unset;
  }

  .calendar {
    button {
      border: 1px solid var(--border-primary);
      border-right: none;
      background-color: var(--gray-0);

      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      padding: 0 6px 0 14px;

      svg {
        height: 20px;
        width: 20px;

        path {
          fill: var(--text-secondary-700);
        }
      }
    }

    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;

      padding-left: 0;
    }
  }
}
