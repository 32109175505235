@use 'sass:map';

@import 'scss/utils/mixins';
$primary-color: rgb(25, 42, 61);
$secondary-color: rgb(58, 79, 102);
$link-color: rgb(40, 114, 250);
$background-color: rgb(250, 251, 252);

.h1{
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
}
.article1, .div, .h2,  .h3 {
    color: $primary-color;
    font-weight: 700;
    margin-bottom: 20px;
}

.article1 {
    background-color: $background-color;
    padding: 40px 10vw;
    font-size: 1.5rem;;
}



.ul, .ul {
    padding-left: 40px;
    list-style-type: disc;
}

.li{
    display: list-item;
    margin-bottom: 20px;
}

.a1 {
    color: $link-color;
    text-decoration-line: underline;
}

.h3, .div1{
    font-size: 30px !important;
    margin: 30px 0 !important;
    line-height: 32px;
}
.back {
    font-size: 2.5rem;
    text-decoration: none;
    height: 44px;
    width: 153px;
    position: relative;
    line-height: 100%;

    display: inline-block;

}