@use 'sass:map';

@import 'scss/utils/mixins';

.tableTitle {
  color: map.get($colors, 'black');
  font: {
    family: var(--font-inter);
    size: 12px;
    weight: 700;
  }
  line-height: 100%;
}