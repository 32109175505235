.selectButton .select {
  border-radius: 8px;
  border: 1px solid var(--border-disabled);
  background-color: var(--border-disabled);

  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 1px;

  overflow: hidden;

  .selectButton {
    background-color: var(--gray-0);
    border: none;

    color: var(--text-tertiary-900);
    font:
      500 12px/20px "Inter",
      sans-serif;

    padding: 10px 8px;

    cursor: pointer;
    flex: 1;

    &.selected {
      background-color: var(--bg-primary-hover);
      font-weight: 700;
    }
  }
}
