.actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
  gap: 16px;

  > * {
    flex: 1;
  }

  button {
    height: inherit;
    min-height: 40px;

    > span {
      flex: unset;
      text-align: left;
    }
  }
}

.hero {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
  gap: 16px;

  > * {
    flex: 1;
  }

  > div:first-of-type {
    height: 166px;
    width: 166px;

    flex: unset;
  }
}

.form {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;

  > * {
    width: unset;
  }

  .row {
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;

    .col {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;

      width: calc(calc(100% / 6) - 13px);
    }

    .description {
      display: inline-flex;
      flex: 1;

      > div {
        flex: 1;

        textarea {
          flex: 1;
        }
      }
    }
  }
}
