.shows {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;

  > * {
    width: 100%;
  }
}

.intro {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex: 1;

  .title,
  .info {
    color: var(--text-primary-900);
  }
  .title {
    font:
      700 20px/30px "Inter",
      sans-serif;
  }
  .info {
    font:
      500 16px/24px "Inter",
      sans-serif;

    span {
      &:first-of-type::after {
        content: "|";
        margin: 0 12px;
      }
    }
  }
}

.infoActions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 0;

  .btn {
    padding: 8px;
    cursor: pointer;
  }
}
