.header {
  background-color: var(--gray-0);
  border-bottom: 1px solid var(--border-disabled);

  height: 65px;
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  inset: 0 0 auto;
  z-index: 100;

  /* transition: width 0.15s 0.05s; */

  @media screen and (min-width: 576px) {
    border-right: 1px solid var(--border-disabled);
    border-bottom: none;

    align-items: flex-start;
    flex-direction: column;

    inset: 0 auto 0 0;
    height: 100%;
    width: 56px;

    > * {
      width: 100%;
    }
  }
  @media screen and (min-width: 768px) {
    width: 312px;
  }

  .content {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;

    overflow-y: scroll;
    /* padding: 16px 12px; */

    @media screen and (min-width: 576px) {
      padding: 24px 8px 24px 16px;
      flex: 1;
    }
    @media screen and (min-width: 768px) {
      padding: 32px 24px;
    }

    > * {
      width: 100%;
    }

    > div {
      position: relative;
      left: -4px;

      > div > p {
        opacity: 0;
        visibility: hidden;
        width: 0;

        @media screen and (min-width: 768px) {
          opacity: 1;
          visibility: visible;
          width: auto;
        }
      }
    }
  }

  .nav {
    background-color: var(--gray-0);

    display: none;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;

    position: fixed;
    inset: 65px 0 0;

    @media screen and (min-width: 576px) {
      background-color: transparent;
      display: flex;
      position: static;
    }

    &.opened {
      display: flex;
      padding: 24px;
    }

    ul li > a,
    ul li > div {
      display: flex;

      span,
      > div:last-of-type:not(:only-of-type) {
        @media screen and (min-width: 576px) {
          visibility: hidden;
          width: 0;
          opacity: 0;
        }
        @media screen and (min-width: 768px) {
          visibility: visible;
          width: auto;
          opacity: 1;
        }
      }

      > div span,
      > div div:last-of-type:not(:only-of-type) {
        @media screen and (min-width: 576px) {
          visibility: hidden;
          width: 0;
          opacity: 0;
        }
        @media screen and (min-width: 768px) {
          visibility: visible;
          width: auto;
          opacity: 1;
        }
      }
    }

    ul ul {
      @media screen and (min-width: 576px) {
        visibility: hidden;
        padding: 0;
        height: 0;
        opacity: 0;
      }
      @media screen and (min-width: 768px) {
        visibility: visible;
        height: auto;
        opacity: 1;

        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    > div {
      /* Submenu */
      > div {
        > div {
          @media screen and (min-width: 768px) {
            display: none;
          }
        }

        span {
          @media screen and (min-width: 576px) {
            visibility: hidden;
            height: 0;
            opacity: 1;
          }

          @media screen and (min-width: 768px) {
            visibility: visible;
            height: auto;
            opacity: 1;
          }
        }
      }

      > ul {
        @media screen and (min-width: 576px) {
          visibility: hidden;
          height: 0;
          opacity: 1;
        }

        @media screen and (min-width: 768px) {
          visibility: visible;
          height: auto;
          opacity: 1;
        }
      }
    }
  }

  &:hover {
    @media screen and (min-width: 576px) and (max-width: 767px) {
      width: 312px;
    }

    .content {
      @media screen and (min-width: 576px) and (max-width: 767px) {
        padding-right: 16px;
      }

      > div > div > p {
        @media screen and (min-width: 576px) and (max-width: 767px) {
          visibility: visible;
          width: auto;
          opacity: 1;
        }
      }

      > .nav a,
      > .nav li {
        > div > span {
          @media screen and (min-width: 576px) and (max-width: 767px) {
            visibility: visible;
            width: auto;
            opacity: 1;
          }
        }
      }

      .nav ul,
      .nav div {
        @media screen and (min-width: 576px) and (max-width: 767px) {
          visibility: visible;
          height: auto;
          opacity: 1;
        }

        li > a,
        li > div {
          span,
          > div:last-of-type:not(:only-of-type) {
            visibility: visible;
            width: auto;
            opacity: 1;
          }
        }
      }

      > div:last-of-type {
        > div:nth-of-type(2) {
          display: flex !important;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
          flex: 1 !important;

          opacity: 1;
          width: auto !important;
          visibility: visible;
        }

        > div:last-of-type {
          display: flex !important;
          opacity: 1;
          visibility: visible;
          /* width: 40px!important; */
        }
      }

      > nav > div > div {
        > div {
          display: none;
        }

        span {
          visibility: visible;
          height: auto;
          opacity: 1;
        }
        /* > div span,
        ul {
          visibility: hidden;
          height: 0;
          opacity: 1;
        } */
      }

      @media screen and (min-width: 768px) {
        padding-right: 24px;
      }
    }

    > div:last-of-type {
      > div:nth-of-type(2),
      > div:last-of-type {
        visibility: visible;
        width: auto !important;
        opacity: 1;
        display: flex !important;
        flex-flow: column nowrap;
      }

      > div:last-of-type {
        width: 40px !important;
      }
    }
  }
}

.mobTriggerContain {
  height: inherit;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 576px) {
    display: none;
  }

  .menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;

    svg {
      height: 40px;
      width: 40px;

      .line {
        fill: none;
        stroke: var(--gray-1);
        stroke-width: 6;
        transition:
          stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
          stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

        &.line1,
        &.line2,
        &.line3 {
          stroke-width: 6;
        }
        &.line1,
        &.line3 {
          stroke-dasharray: 60 207;
        }
        &.line2 {
          stroke-dasharray: 60 60;
        }
      }
    }

    &.opened {
      svg {
        .line {
          &.line1,
          &.line2,
          &.line3 {
            stroke-width: 6;
          }
          &.line1,
          &.line3 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
          }
          &.line2 {
            stroke-dasharray: 1 60;
            stroke-dashoffset: -30;
          }
        }
      }
    }
  }
}
