.stats {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 16px;

  > * {
    flex: 1;
  }

  .stat {
    border: 1px solid var(--border-disabled);
    border-radius: 12px;
    background-color: var(--gray-0);
    box-shadow: var(--shadow-primary);

    padding: 24px;

    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;

    > * {
      width: 100%;
    }

    .row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;

      > div {
        background-color: var(--brand-secondary);
        border-radius: 50%;

        padding: 12px;

        > svg {
          height: 24px;
          width: 24px;

          path {
            stroke: var(--brand-light-fg);
          }
        }
      }
    }

    p {
      color: var(--text-tertiary-900);
      font:
        600 36px/44px "Inter",
        sans-serif;
    }
  }
}
