@use "sass:map";
@use "sass:color";

@import "scss/utils/variables";
@import "scss/utils/mixins";

.pageHeader {
  @include flexbox($jc: space-between, $gap: 1rem);
  background-color: adjust-color(
    $color: map-get($map: $colors, $key: "black"),
    $alpha: -0.97
  );
  min-height: 6.6rem;
  padding: 0 2rem;

  &Title {
    margin-bottom: 0;
  }

  .showHeaderSection {
    @include flexbox($gap: 1rem);
  }
}