.tile {
  border: 1px solid var(--border-disabled);
  border-radius: 8px;
  background-color: var(--gray-0);

  padding: 16px;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;

  &.complete {
    border-left: 8px solid var(--red-base);
    padding-left: 9px;

    &.shared {
      border-left-width: 1px;
      border-left-color: var(--border-disabled);

      padding-left: 16px;

      .title,
      .longname {
        color: var(--text-disabled);
      }

      &.selected {
        border-color: var(--red-base);
      }
    }
  }

  .title {
    color: var(--text-tertiary-900);
    font:
      700 16px/24px "Inter",
      sans-serif;

    &.titleComplete {
      color: var(--red-base);
    }
  }

  .longname {
    color: var(--text-tertiary-600);
    font:
      400 12px/18px "Inter",
      sans-serif;
  }

  .leftCont {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }

  .rightCont {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 8px;
  }
}
