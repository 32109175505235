.classRiders {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;

  > * {
    width: 100%;
  }

  > div {
    button:last-of-type {
      margin-left: 0;
    }

    &:nth-of-type(2) {
      width: unset;
    }

    &:last-of-type {
      margin-top: -56px;
    }
  }
}
