.userBlock {
  background-color: var(--gray-0);

  display: none;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  position: relative;
  padding: 12px;

  @media screen and (min-width: 576px) {
    display: flex;
    flex-wrap: nowrap;
    height: 64px;
  }

  &.opened {
    display: flex;

    position: fixed;
    inset: auto 0 0;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0 16px auto;

    background-color: var(--border-disabled);
    height: 1px;
  }

  .avatar {
    border-radius: 20px;
    border: 1px solid rgb(0 0 0 / 8%);
    background-color: var(--gray-100);

    height: 40px;
    width: 40px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    order: 0;

    @media screen and (min-width: 576px) {
      height: 30px;
      width: 30px;
    }
    @media screen and (min-width: 768px) {
      height: 40px;
      width: 40px;
    }

    > div {
      height: 18px;
      width: 18px;
      /* height: inherit;
      width: inherit; */

      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      @media screen and (min-width: 768px) {
        height: 24px;
        width: 24px;
      }
    }
  }

  .info {
    order: 2;
    width: 100%;

    @media screen and (min-width: 310px) {
      order: unset;
      margin-right: auto;
      min-width: 175px;
      width: auto;
    }
    @media screen and (min-width: 576px) {
      flex: 1 !important;
      visibility: hidden;
      width: 0 !important;
      opacity: 0;
      display: none !important;
    }
    @media screen and (min-width: 768px) {
      visibility: visible;
      width: auto !important;
      opacity: 1;

      display: flex !important;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
    }

    > p {
      color: var(--text-tertiary-600);
      font:
        400 14px/20px "Inter",
        sans-serif;

      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media screen and (min-width: 576px) {
        max-width: 175px;
      }

      &:first-of-type {
        color: var(--text-secondary-700);
        font-weight: 700;
      }
    }
  }

  .logout {
    border-radius: 20px;
    border: 1px solid transparent;
    background-color: transparent;

    height: 40px;
    width: 40px;

    cursor: pointer;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    order: 1;

    transition:
      background-color 0.8s,
      border-color 0.2s;

    @media screen and (min-width: 576px) {
      flex: unset !important;
      visibility: hidden;
      width: 0 !important;
      opacity: 0;
      display: none !important;
    }

    @media screen and (min-width: 768px) {
      display: flex !important;
      width: 40px !important;
      opacity: 1;
      visibility: visible;
    }
    &:hover {
      background-color: var(--gray-100);
      border-color: rgb(0 0 0 / 8%);
    }
  }
}
