@use 'sass:map';

@import 'scss/utils/mixins';

.inputSwitch {
  align-items: center;
  display: flex;
  font-size: 12px;
  gap: 10px;

  .p-inputswitch {
    height: 20px;
    width: 40px;

    .p-inputswitch-slider {
      background-color: map.get($colors, 'ltGrey');
      box-shadow: none;

      &:before {
        @include square(16px);
        left: 1px;
        margin: {
          top: -8px;
        }
      }
    }

    &.p-inputswitch-checked .p-inputswitch-slider {
      background-color: map.get($colors, 'blue');

      &:before {
        @include css3-prefix(transform, translateX(20px));
      }
    }
  }
}