.tag {
  border-radius: 15px;
  border-style: solid;
  border-width: 1px;

  color: var(--text-secondary-700);
  font:
    500 14px/22px "Inter",
    sans-serif;

  padding: 0 10px;
  display: inline-block;
}
