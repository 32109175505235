.container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 32px;

  margin-right: 16px;

  @media screen and (min-width: 576px) {
    margin-right: 0;
    align-items: flex-start;
  }

  .logo {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    > div {
      background: linear-gradient(
        315deg,
        var(--logo-blue) 0%,
        var(--logo-red) 100%
      );
      border-radius: 8px;

      height: 32px;
      width: 32px;

      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      > svg {
        height: 26px;
        width: 26px;
      }
    }

    p {
      color: var(--gray-1);
      font:
        400 22px/28px "Korolev",
        sans-serif;

      position: relative;
      top: 4px;

      display: none;

      @media screen and (min-width: 576px) {
        display: block;
      }
    }
  }
}
