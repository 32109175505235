.container {
  color: var(--gray-1);
  font:
    600 20px/28px "Inter",
    sans-serif;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 24px;

  min-height: 100vh;

  margin: 0 20px;
  text-align: center;

  h1 {
    font-size: 30px;
    line-height: 38px;
    letter-spacing: 1px;

    @media screen and (min-width: 576px) {
      font-size: 40px;
      line-height: 48px;
    }
  }
  a {
    background-color: var(--red-700);
    border-radius: 8px;

    color: var(--gray-0);
    font:
      600 14px/24px "Inter",
      sans-serif;
    text-decoration: none;

    padding: 12px 24px;
  }
}
