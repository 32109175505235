body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--gray-0);
  font:
    400 14px/18px "Inter",
    sans-serif;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
dl {
  margin: 0;
  padding: 0;
}

h1 {
  color: var(--text-primary-900);
  font:
    700 20px/30px "Inter",
    sans-serif;
}

h3 {
  color: var(--text-primary-900);
  font:
    600 16px/24px "Inter",
    sans-serif;
}

a {
  text-decoration: unset;
}
