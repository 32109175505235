.classes {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;

  > * {
    width: 100%;
  }

  > div .subStat {
    position: relative;
    padding-left: 16px;

    color: var(--text-primary-900);
    font:
      600 16px/24px "Inter",
      sans-serif;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      width: 1px;
      height: 30px;

      background-color: var(--border-primary);
    }
  }
}
