// file: client/src/components/PageHeader/PageHeader.module.scss

@use "sass:map";
@use "sass:color";

@import "scss/utils/variables";
@import "scss/utils/mixins";

$mh: 6.6rem;

.pageHeader {
  @include flexbox($jc: space-between, $gap: 1rem);
  @include getColor("background", color.adjust(#000000, $alpha: -0.97));
  @include padx(2rem);
  height: $mh;

  .title {
    @include mar($bottom: 0);
  }

  .comp {
    @include flexbox($gap: 1rem);
  }
}
