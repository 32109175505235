:root {
  /* Blues */
  --blue-base: #3142af;

  /* Grays */
  --gray-0: #ffffff;
  --gray-100: #f2f4f7;
  --gray-500: #808080;
  --gray-1: #000000;

  /* Greens */
  --green-50: #ecfdf3;
  --green-200: #abefc6;
  --green-700: #067647;

  /* Reds */
  --red-base: #d52c51;
  --red-700: #aa223f;

  /* Text */
  --text-placeholder: #667085;
  --text-secondary-700: #344054;
  --text-secondary-hover: #182230;
  --text-tertiary-600: #475467;
  --text-tertiary-900: #101828;
  --text-disabled: #98a2b3;

  /* Brand */
  --brand-primary: #eff1fb;
  --brand-secondary: #cfd4f2;
  --brand-light-fg: #202b72;

  /* Logo */
  --logo-blue: #122eec;
  --logo-red: #d62c50;

  /* Borders */
  --border-primary: #d0d5dd;
  --border-secondary: #d0d5dd;
  --border-disabled: #eaecf0;

  /* Backgrounds */
  --bg-primary: #3142af;
  --bg-primary-hover: #eff1fb;
  --bg-secondary: #ffffff;
  --bg-disabled: #f2f4f7;
  --bg-thead: #f9fafb;

  /* Shadows */
  --shadow-primary: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  --shadow-secondary: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  --shadow-secondary-hover: 0px 1px 3px 0px rgba(16, 24, 40, 0.15),
    0px 1px 2px 0px rgba(16, 24, 40, 0.1);
}
