.mainMenu {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;

  list-style: none;

  width: 100%;

  > li > div.active::after {
    display: none;
  }

  li {
    width: 100%;

    .active {
      position: relative;

      &::after {
        background-color: var(--bg-primary-hover);
        border-radius: 6px;

        content: "";
        position: absolute;
        inset: 0 -8px;
        z-index: -1;
      }
    }

    .link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;

      color: var(--text-secondary-700);
      font:
        600 16px/24px "Inter",
        sans-serif;

      padding: 8px 0;
      position: relative;

      cursor: pointer;

      > div:nth-of-type(2) {
        margin-left: auto;
      }
    }
  }
}

.submenu {
  list-style: none;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;

  padding-top: 10px;
  padding-bottom: 10px;

  li {
    position: relative;

    &.isActive {
      &::after {
        background-color: var(--bg-primary-hover);
        border-radius: 6px;

        content: "";

        position: absolute;
        inset: -4px -8px;
        z-index: -1;
      }
    }

    a {
      border-radius: 6px;

      display: block;

      color: var(--text-secondary-700);
      font:
        600 16px/24px "Inter",
        sans-serif;

      padding: 8px 12px 8px 36px;
      width: 100%;
    }
  }
}

.nestedMenu {
  position: relative;

  padding-top: 24px;
  width: 100%;

  > * {
    width: 100%;
  }

  &::before {
    background-color: var(--border-disabled);
    height: 1px;

    content: "";

    position: absolute;
    inset: 0 -8px auto;
    z-index: -1;
  }

  .title {
    color: var(--text-secondary-700);
    font:
      600 16px/24px "Inter",
      sans-serif;

    padding: 0 0 8px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    > div {
      display: none;

      @media screen and (min-width: 576px) {
        display: block;
      }
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  ul {
    list-style: none;
  }
}
