.dataScroller > div {
  padding: 0;

  ul {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;

    li {
      width: 100%;
    }
  }
}
