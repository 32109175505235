.button {
  background-color: var(--border-primary);
  border: 1px solid var(--border-primary);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 6px;

  color: var(--gray-0);
  font:
    600 14px/20px "Inter",
    sans-serif;

  padding: 9px 16px;

  svg * {
    stroke: var(--gray-0) !important;
  }

  &.primary {
    background-color: var(--bg-primary);
    border-color: var(--bg-primary);

    &.link,
    &.outlined {
      color: var(--bg-primary);

      svg * {
        stroke: var(--bg-primary) !important;
      }
    }

    &.outlined {
      border-color: var(--bg-primary);
    }
  }

  &.secondary {
    background-color: var(--border-secondary);
    border-color: var(--border-secondary);
    color: var(--text-secondary-700);

    svg * {
      stroke: var(--text-secondary-700) !important;
    }

    &.link {
      color: var(--text-secondary-700);
    }

    &.outlined {
      background-color: var(--bg-secondary);
      color: var(--text-secondary-700);
    }
  }

  &.danger {
    background-color: var(--red-700);
    border-color: var(--red-700);

    &.link,
    &.outlined {
      color: var(--red-700);

      svg * {
        stroke: var(--red-700) !important;
      }
    }

    &.outlined {
      border-color: var(--red-700);
    }
  }

  &.link {
    background-color: unset;
    border: unset;
    border-radius: 0;
    box-shadow: unset;
    color: var(--border-primary);
    font-size: 14px;
    line-height: 20px;
    margin-right: auto;
    padding: 0;
    width: unset !important;

    svg * {
      stroke: var(--blue-base);
    }
  }

  &.outlined {
    background-color: unset;
    border-color: var(--border-primary);
    color: var(--border-primary);

    svg * {
      stroke: var(--border-primary);
    }
  }

  &.btnGroup {
    background-color: var(--gray-0);
    border-color: unset;

    color: var(--text-secondary-700);
    font:
      600 14px/20px "Inter",
      sans-serif;

    padding: 8px 16px;
  }

  &:disabled {
    background-color: var(--bg-disabled);
    border-color: var(--border-disabled);
    box-shadow: unset;
    color: var(--text-disabled);

    svg * {
      stroke: var(--text-disabled) !important;
    }
  }
}
