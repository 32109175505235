.Login {
  height: 100vh;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;

  > * {
    width: 100%;
  }

  .navBlock {
    header {
      border-right: none;
      border-bottom: 1px solid var(--border-disabled);

      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;

      position: static;
      padding-top: 0;

      height: 95px;
      width: 100%;

      > div {
        &:first-of-type {
          padding-top: 0;
          padding-bottom: 0;

          > nav {
            display: none;
          }
        }

        &:last-of-type {
          margin-left: auto;
          padding-top: 0;
          padding-bottom: 0;

          position: static;
          width: auto;

          &::before {
            display: none;
          }

          p {
            max-width: 225px;
          }
        }
      }
    }
  }

  .container {
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;
    flex-grow: 1;
    margin: 0 auto;

    text-align: center;

    overflow: hidden;
    max-width: 1920px;
    width: 94%;

    .copy {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;

      p {
        color: var(--text-secondary-900);
        font:
          400 16px/24px "Inter",
          sans-serif;
        margin: 0;
        max-width: 400px;
        width: 94%;

        a {
          color: var(--blue-base);
        }
      }
    }

    .info {
      background-color: var(--gray-0);
      border: 1px solid var(--border-primary);
      border-radius: 8px;
      box-shadow: var(--shadow-primary);

      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;

      padding: 15px;
      margin: 0 auto;

      max-width: 400px;
      width: 94%;

      .row {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;

        width: 100%;

        .label {
          color: var(--gray-1);
          width: 45px;
        }

        .val {
          flex: 1;
          font-weight: 600;
          text-align: left;
        }
      }
    }
  }
}
