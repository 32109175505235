.emptyBlock {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  height: 100%;
  min-height: 90vh;

  .inner {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 24px;

    position: relative;

    max-width: 512px;
    width: 90%;

    > * {
      text-align: center;
    }

    .content {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      gap: 16px;

      .graphic {
        height: 118px;
        width: 152px;

        position: relative;

        > div {
          position: absolute;

          &:first-of-type {
            top: 0;
            left: 24px;

            height: 104px;
            width: 104px;
          }
          &:nth-of-type(2) {
            filter: drop-shadow(0px 8px 8px rgba(16, 24, 40, 0.03))
              drop-shadow(0px 20px 24px rgba(16, 24, 40, 0.08));

            top: 16px;
            left: 0;
          }
          &:nth-of-type(3) {
            top: 14px;
            left: 13px;
            transform: scale(0.75);
          }
          &:nth-of-type(4) {
            top: 4px;
            right: 16px;
            transform: scale(0.65);
          }
          &:nth-of-type(5) {
            top: 24px;
            right: 0;
          }
          &:nth-of-type(6) {
            bottom: 2px;
            left: 11px;
            transform: scale(1.1);
          }
        }
      }

      .intro {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        gap: 4px;

        h1 {
          color: var(--text-secondary-700);
          font:
            600 16px/24px "Inter",
            sans-serif;
        }

        p {
          color: var(--text-tertiary-600);
          font:
            400 14px/20px "Inter",
            sans-serif;
        }
      }
    }

    .dots {
      opacity: 0.55;

      position: absolute;
      z-index: -1;

      .mask {
        position: absolute;
        inset: 0;
        z-index: -1;

        mask-image: radial-gradient(
          50% 50% at 50% 50%,
          #000 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
  }
}
