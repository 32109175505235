.rider {
  /* height: 100vh; */

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;

  padding-top: 125px;
  padding-bottom: 32px;

  > * {
    width: 100%;
  }

  .navBlock {
    position: fixed;
    inset: 0 0 auto 0;

    header {
      border-right: none;
      border-bottom: 1px solid var(--border-disabled);

      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;

      position: static;
      padding-top: 0;

      height: 95px;
      width: 100%;

      > div {
        &:first-of-type {
          padding-top: 0;
          padding-bottom: 0;

          > nav {
            display: none;
          }
        }

        &:last-of-type {
          margin-left: auto;
          padding-top: 0;
          padding-bottom: 0;

          position: static;
          width: auto;

          &::before {
            display: none;
          }

          p {
            max-width: 225px;
          }
        }
      }
    }
  }

  .container {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    flex-grow: 1;

    margin: 0 auto;

    overflow: hidden;
    max-width: 1920px;
    width: 94%;

    > * {
      width: 100%;
    }

    h1 {
      margin-bottom: 12px;
    }

    h2 {
      margin-top: 12px;
    }

    .content {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      gap: 36px;

      height: 100%;

      .leftColumn {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;

        max-width: 430px;
        width: 100%;

        > * {
          width: 100%;
        }

        .list {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 16px;
          flex: 1;

          overflow-y: scroll;
          padding-bottom: 36px;

          > * {
            width: 100%;
          }
        }
      }

      .rightColumn {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;

        > * {
          width: 100%;
        }

        .table {
          border-radius: 12px;
          border: 1px solid var(--border-disabled);

          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;

          overflow: hidden;

          > * {
            width: 100%;
          }
        }

        button {
          margin-top: 36px;
          margin-left: auto;

          min-width: 175px;
          width: unset;
        }

        .headerRow {
          background-color: var(--bg-thead);
          border-bottom: 1px solid var(--border-disabled);

          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;

          .headerCol {
            flex: 1;
            padding: 12px 24px;

            color: var(--text-tertiary-900);
            font:
              500 12px/18px "Inter",
              sans-serif;

            &:first-of-type,
            &:last-of-type {
              text-align: center;
            }
            &:first-of-type {
              flex: 0.5;
            }
            &:nth-of-type(3) {
              flex: 0.75;
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}
