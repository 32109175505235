.riderList {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  .rider {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    border-bottom: 1px solid var(--border-disabled);

    width: 100%;

    &:last-of-type {
      border-bottom: none;
    }

    > * {
      flex: 1;
    }

    .col {
      padding: 18px 24px;

      color: var(--gray-500);
      font:
        400 16px/24px "Inter",
        sans-serif;

      &:first-of-type {
        flex: 0.5;
        font-weight: 700;
        text-align: center;
      }
      &:nth-of-type(3) {
        flex: 0.75;
        margin-right: 16px;
      }
      &:last-of-type {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        gap: 4px;
      }
    }
  }
}

.noRiders {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;

  p {
    color: var(--gray-500);
    font:
      700 24px/24px "Inter",
      sans-serif;

    padding: 24px;
  }
}
