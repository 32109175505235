$mh: 6.6rem;
@use "sass:map";
@use "sass:color";

@import "scss/utils/variables";
@import "scss/utils/mixins";

.awards {
  @include flexbox($fd: column, $ai: flex-start, $fw: wrap, $gap: 4rem);
  margin: {
    top: 20px;
    bottom: 30px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  // Title
  &Title {
    @include bd-bottom($color: color.adjust(map.get($colors, 'black'), $lightness: 75.4%));
    @include flexbox($jc: space-between);
    @include pad($bottom: 1rem, $left: 2rem);
    width: 100%;

    h1 {
      margin-bottom: 0;
    }
  }

  // Block
  &Block {
    @include flexbox($ai: stretch, $gap: 20px, $fw: wrap);
    @include padx(1rem);
    width: 100%;
  }
}


// Award
.award {
  @include border-rad(1rem);
  @include flexbox($fd: column, $gap: 10px);
  @include padxy(20px);
  background-color: map.get($colors, 'whiteSmoke');
  max-width: 218px;
  width: 100%;

  .img {
    @include border-rad(5px);
    @include padxy(10px);
    background-color: map.get($colors, 'white');
    border: 1px solid map.get($colors, 'veryLightGray');
    max-width: 92px;
    object-fit: contain;

    &.plcImg {
      @include border-rad(14px);
      @include padxy(6px);
      background-color: map.get($colors, 'gainsboro');
      max-width: none;
    }
  }

  > h3 {
    @include font($size: 18px, $weight: 600);
    @include mar($top: 1rem);
    line-height: 22px;
    margin-bottom: 0;
    text-align: center;
  }

  > p {
    margin-top: auto;
  }

  .buttonBlock {
    @include flexbox($gap: 1rem);
    @include mar($top: 10px);
  }
}
