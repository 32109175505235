@import "scss/utils/mixins";

.selectContainer {
  font-size: 12px;
  min-width: 250px;

  .ddSelect > div {
    background-color: #fff;
    border-color: #51986a;
    box-shadow: 0 0 1px #51986a;
  }
}

.eventImgBtn,
.locationImg {
  @include border($color: #dbdbdb);
  @include border-rad(4px);
  @include flexbox;
  @include getColor('background',#fff);
  @include relative;
  border-width:5px ;
  height: 205px;
  overflow: hidden;

  .img {
    // @include border-rad(16px);
    // @include getColor('background',#d8d8d8);
    // @include padxy(7px);
    // @include pushAuto;
    // height: auto;
    // width: 120px;
    width: 100%;
  }

  .btn {
    @include absolute(0,0,0,0);
    @include border-rad(4px);
    @include padxy(0);
    height: 100%;
  }
}
.locationImg {
  height: 157px;
}


.modalSplitClass {
  .modalBody {
    @include flexbox($fd: column, $gap: 20px);
    
    > * {
      width: 100%;
    }

    .contentHeader {
      @include flexbox($jc: space-between);

      &Left {
        @include flexbox($ai: flex-start, $fd: column, $gap: 10px);
        flex: 1;

        h3 {
          margin: {
            left: 0;
            bottom: 0;
          }
        }
        p {
          font-size: 12px;
        }
      }

      &Right {
        @include flexbox($jc: flex-end, $gap: 10px);
        flex: 1;

        p {
          margin: {
            bottom: 0;
          }
        }
        input {
          height: 28px!important;
          min-width: unset!important;
          width: 40px!important;
        }
      }
    }
  }
}
.notesInput {
  @include getColor('background',rgba(0,0,0,0.03));
  @include flexbox($ai: flex-start, $fd: column, $gap: 30px);
  @include pad($top: 35px, $bottom: 40px);
  @include padx(50px);
  flex: 1;

  > * { width: 100%; }

  .item {
    @include flexbox($ai: flex-start, $fd: column, $gap: 8px);

    > * { width: 100%; }

    h3 {
      margin-bottom: 0;
    }

    textarea {
      min-height: 125px;
    }

    Button {
      @include getColor('background',transparent);
      border-style: dashed;
      font-size: 12px;
      line-height: 28px;
      text-decoration: underline;
    }
  }
}

.statsFilter {
  @include flexbox(
    $gap: 20px,
    // $fw: wrap
  );

  .showStat {
    order: 0;
  }
  > form {
    @include flexbox($jc: flex-end, $gap: 20px);
    max-width: 175px;
    order: 2;
    width: 100%;

    > div {
      padding: 0;

      > div {
        max-width: none;
        width: 100%;
      }
    }
  }

  &.regs {
    flex-wrap: wrap;

    .showStat {
      margin-top: -10px;
    }

    > div {
      // table
      &:not([data-type="searchoutside"], .showStat) {
        order: 3;
        width: 100%;
      }
    }

    > div[data-type='searchoutside'] {
      order: 1;
      margin-left: auto;
      max-width: 200px;
      width: 100%;

      > div {
        padding: 0;

        > input {
          border: {
            color: #979797;
            radius: 14px;
          }
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}

.showStat {
  @include flexbox($ai: flex-start, $fd: column, $gap: 8px);
  
  .label,
  .entry {
    font-size: 12px;
    line-height: 16px;
  }
  .label {
    font-weight: 700;
  }
}

.noScroll {
  overflow-y: visible !important;
}

