.divTable {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;

  > * {
    width: 100%;
  }
}

.search {
  text-align: right;

  input {
    background-color: var(--gray-0);
    border: 1px solid var(--border-primary);
    border-radius: 8px;
    box-shadow: var(--shadow-primary);

    color: var(--text-placeholder);
    font:
      400 12px/18px "Inter",
      sans-serif;

    margin-left: auto;
    padding: 10px 14px;

    @media screen and (min-width: 576px) {
      font-size: 14px;
    }

    &:focus {
      outline: none;
    }
  }
}

.datatable {
  > div:first-of-type {
    border: 1px solid var(--border-disabled);
    border-radius: 12px 12px 0 0;
    box-shadow: var(--shadow-primary);
    overflow: hidden;
  }

  table {
    thead > tr > th {
      color: var(--text-tertiary-600);
      font:
        500 12px/18px "Inter",
        sans-serif;

      padding: 12px 24px;

      background-color: var(--bg-thead);
      border-bottom: 1px solid var(--border-disabled);

      &[aria-sort="none"] > div[data-pc-section="headercontent"] {
        svg {
          height: 13px;
          width: 13px;

          path {
            fill: var(--text-disabled) !important;
          }
        }
      }

      > div[data-pc-section="headercontent"] {
        align-items: center;
        gap: 4px;

        span[data-pc-section="sort"] {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
        }
        span[data-pc-section="sort"],
        svg {
          height: 16px;
          width: 16px;

          path {
            fill: var(--blue-base);
          }
        }
      }

      &:last-of-type > div > span > button {
        border-radius: 4px;

        font:
          700 12px/20px "Inter",
          sans-serif;

        padding: 0 12px;
        position: absolute;
        inset: 10px 10px auto auto;
      }

      &:only-of-type > div {
        justify-content: flex-start;

        > span {
          position: static;
        }
      }
    }

    tbody > tr {
      color: var(--text-tertiary-600);
      font:
        400 14px/20px "Inter",
        sans-serif;

      &:last-of-type {
        td {
          border-bottom: none;
        }
      }

      &[data-pc-section="rowgroupheader"] {
        background-color: var(--brand-primary);

        color: var(--text-tertiary-600);
        font:
          700 16px/24px "Inter",
          sans-serif;

        > td {
          border-bottom-color: var(--gray-0);
          font-weight: 700 !important;

          padding: 12px 24px;
          position: relative;

          > button {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            box-shadow: unset;

            padding: 0;
            position: absolute;
            inset: 0;

            svg {
              margin-right: 24px;
              margin-left: auto;

              * {
                fill: var(--text-tertiary-600);
                stroke: unset !important;
              }
            }
          }
        }
      }

      > td {
        border-bottom: 1px solid var(--border-disabled);
        padding: 16px 24px;

        &:first-of-type {
          color: var(--text-tertiary-900);
          font-weight: 500;
        }

        &:last-of-type > div > div:not(.p-checkbox-box) {
          display: inline-flex;
          padding: 10px;
          width: auto;
        }

        svg[data-pc-section="rowreordericon"] {
          height: 24px;
          width: 24px;
          display: block;

          path {
            fill: var(--text-disabled);
          }
        }

        input:not([type="checkbox"]) {
          text-align: right;
          width: 75px;
        }
      }
    }

    div[data-pc-name="checkbox"] {
      position: relative;

      input[type="checkbox"] {
        position: absolute;
        inset: 0;

        opacity: 0;

        & + div[data-pc-section="box"] {
          border: 1px solid var(--border-primary);
          border-radius: 6px;

          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;

          height: 20px;
          width: 20px;
          background-color: var(--gray-0);
        }

        &:checked + div[data-pc-section="box"] {
          background-color: var(--brand-light-fg);
          border-color: var(--brand-light-fg);

          svg {
            * {
              fill: var(--gray-0);
              stroke: var(--gray-0) !important;
            }
          }
        }
      }
    }
  }
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  flex: 1;
}

.helper {
  display: block;
  font:
    400 10px/10px "Inter",
    sans-serif;
}
