.filterBtnGroup {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1px;

  background-color: var(--border-primary);
  border: 1px solid var(--border-primary);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

  overflow: hidden;

  button {
    background-color: var(--gray-0);
    border-radius: 0;
    border: none;
    box-shadow: none;

    cursor: pointer;

    padding: 9px 16px;

    color: var(--text-secondary-700);
    font:
      600 14px/20px "Inter",
      sans-serif;

    &.active {
      background-color: var(--bg-primary-hover);
    }
  }
}
