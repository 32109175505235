.p-paginator {
  background-color: var(--gray-0);
  border: 1px solid var(--border-disabled);
  border-top: unset;
  border-radius: 0 0 12px 12px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  padding: 12px 24px;

  position: relative;

  .p-disabled {
    color: var(--text-disabled) !important;
    border-color: var(--border-disabled) !important;
    box-shadow: unset !important;
  }

  > .p-link {
    border-radius: 8px;
    border: 1px solid var(--border-secondary);
    box-shadow: var(--shadow-primary);

    color: var(--text-secondary-700);
    font:
      600 14px/20px "Inter",
      sans-serif;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 4px;

    padding: 8px 12px;

    &.p-paginator-first,
    &.p-paginator-last,
    &.p-paginator-prev,
    &.p-paginator-next {
      position: relative;
    }

    &.p-paginator-first {
      &::after {
        content: "First";
      }
    }

    &.p-paginator-last {
      &::before {
        content: "Last";
      }
    }

    &.p-paginator-prev {
      margin-right: auto;
      margin-left: 10px;

      &::after {
        content: "Prev";
      }
    }

    &.p-paginator-next {
      margin-left: auto;
      margin-right: 10px;

      &::before {
        content: "Next";
      }
    }
  }

  .p-paginator-pages button {
    height: 40px;
    width: 40px;

    color: var(--text-tertiary-600);
    font:
      500 14px/20px "Inter",
      sans-serif;

    &.p-highlight {
      background-color: var(--brand-primary);
      border-radius: 8px;

      color: var(--text-secondary-hover);
    }
  }
}

.p-dropdown {
  background-color: var(--gray-0);
  border: 1px solid var(--border-secondary);
  border-radius: 8px;
  box-shadow: var(--shadow-primary);

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 4px;

  margin-left: 10px;
  padding: 9px 12px;

  .p-dropdown-label {
    color: var(--text-secondary-700);
    font:
      600 14px/20px "Inter",
      sans-serif;
  }

  .p-dropdown-trigger {
    svg {
      width: 12px;
    }
  }
}
.p-dropdown-panel {
  background-color: var(--gray-0);
  border: 1px solid var(--border-secondary);
  border-radius: 8px;
  box-shadow: var(--shadow-primary);
  overflow: hidden;

  .p-dropdown-item {
    padding: 3px 12px;

    &.p-highlight {
      background-color: var(--bg-primary) !important;

      color: var(--gray-0);
      font-weight: 600;
    }

    &.p-focus {
      background-color: var(--bg-primary-hover);
    }
  }
}
