@import 'scss/utils/mixins';

.contentHeader {
  @include flexbox($jc: space-between);

  &Right {
    @include flexbox($jc: flex-end, $gap: 10px);
    // flex: 1;

    p {
      font-size: 12px;

      margin: {
        bottom: 0;
      }
    }
    input {
      height: 28px!important;
      min-width: unset!important;
      width: 40px!important;
    }
  }
}