@use 'sass:map';

@import 'scss/utils//mixins';

.remove {
  @include flexbox($fd: column);

  p {
    color: map.get($colors, 'black');
    font: {
      family: var(--font-inter);
      size: 20px;
      weight: 700;
    }
    line-height: 30px;
  }

  .emp {
    @include border($color: map.get($colors, 'whiteSmoke'));
    @include padx(35px);
    @include pady(10px);
    background-color: map.get($colors,'white');
    box-shadow: 0 0 4px 4px #00000006;
  }
}